<template>
  <DgOverview :widget="filteredWidget" />
</template>
<script>
import MixinFixWidget from "@/mixins/fixWidget";
import { DgOverview } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetKpi",
  mixins: [MixinFixWidget],
  components: {
    DgOverview,
  },
  computed: {
    filteredWidget() {
      const widgetData = this.widget?.data || [];

      const filteredWidget = {
        ...this.widget,
        data: widgetData.filter((item) => item.type),
      };
      return filteredWidget;
    },
  },
};
</script>
